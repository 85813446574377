import styled from '@emotion/styled';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Layout } from '../components';
import PostPreview from '../components/PostPreview';
import Section from '../components/Section';
import SectionDescription from '../components/SectionDescription';
import SectionTitle from '../components/SectionTitle';
import { categoryUrl } from '../helper/urls';


const WelcomeText = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
`;


class Index extends Component {
  render() {
    const {
      data: { homepage, posts, categories, latestPosts }
    } = this.props;

    const postsByCategoryUid = posts.nodes.reduce(
      (objectsKeyValue, object) => {
        const categories = object.data.categories.map(data => data.category.uid);
        let returnValue = objectsKeyValue;
        categories.forEach(category => {
          returnValue = {
            ...objectsKeyValue,
            [category]: (returnValue[category] || []).concat(object)
          };
        });
        return returnValue;
      },
      {}
    );

    return (
      <Layout>
        <Section featured>
          <SectionTitle centered>{homepage.data.welcomeTextTitle.text}</SectionTitle>
          <WelcomeText dangerouslySetInnerHTML={{ __html: homepage.data.welcomeText.html }} />
        </Section>
        <Section>
          <SectionTitle centered>Neuste Artikel</SectionTitle>
          {latestPosts.nodes.map((value) => (
            <PostPreview key={value.uid} node={value} />
          ))}
        </Section>
        {categories.nodes.map((value) => {
          let posts = postsByCategoryUid[value.uid];
          if (posts.length === 0) {
            return;
          }
          return (
            <Section>
              <Link to={categoryUrl(value.uid)}>
                <SectionTitle>{value.data.name}</SectionTitle>
              </Link>
              {
                value.data.description.text &&
                <SectionDescription>{value.data.description.text}</SectionDescription>
              }
              {posts && posts.map((value, index) => {
                if (index >= 3) {
                  return null;
                }
                return (
                  <PostPreview node={value} invert />
                );
              })}
            </Section>
          );
        })}
      </Layout>
    );
  }
}

export default Index;

Index.propTypes = {
  data: PropTypes.shape({
    homepage: PropTypes.shape({
      data: PropTypes.shape({
        title: PropTypes.shape({
          text: PropTypes.string.isRequired
        }),
        content: PropTypes.shape({
          html: PropTypes.string.isRequired
        })
      })
    }),
    categories: PropTypes.shape({
      nodes: PropTypes.array.isRequired
    }),
    latestPosts: PropTypes.shape({
      nodes: PropTypes.array.isRequired
    }),
    posts: PropTypes.shape({
      nodes: PropTypes.array.isRequired
    })
  }).isRequired
};

export const pageQuery = graphql`
  query IndexQuery {
    homepage: prismicHomepage {
      data {
        welcomeText: welcome_text {
          html
          text
        }
        welcomeTextTitle: welcome_text_title {
          text
        }
      }
    }
    categories: allPrismicCategory(sort: {fields: [data___name], order: ASC}) {
      nodes {
        uid
        data {
          name
          description {
            text
          }
        }
      }
    }
    latestPosts: allPrismicPost(sort: {fields: [data___date], order: DESC}, limit: 3) {
      nodes {
        uid
        data {
          title {
            text
          }
          date(formatString: "DD.MM.YYYY")
          titleImage: title_image {
            alt
            localFile {
              childImageSharp {
                fluid(maxWidth: 496) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    posts: allPrismicPost(sort: { fields: [data___date], order: DESC }) {
      nodes {
        uid
        data {
          title {
            text
          }
          date(formatString: "DD.MM.YYYY")
          categories {
            category {
              uid
            }
          }
          titleImage: title_image {
            alt
            localFile {
              childImageSharp {
                fluid(maxWidth: 496) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
