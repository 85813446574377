import styled from '@emotion/styled';


const SectionDescription = styled.div`
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-left: 4px ${props => props.theme.colors.primary} solid;
  font-size: 1rem;
  line-height: 1.25rem;
  margin-bottom: 16px;
`;

export default SectionDescription;
