import styled from '@emotion/styled';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { postUrl } from '../helper/urls';


const Container = styled.div`
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
  max-width: 496px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const ImageContainer = styled.div`
  margin-bottom: 8px;
`;

const Meta = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 4px;
`;

const Title = styled(props => <Link {...props} />)`
  display: block;
  text-decoration: none;
  line-height: 1.5rem;
  font-family: 'Lato', sans-serif;
  font-size: 1.25rem;
`;

export default ({ node, invert }) => (
  <Container invert={invert}>
    <ImageContainer>
      <Img fluid={node.data.titleImage.localFile.childImageSharp.fluid} />
    </ImageContainer>
    <Meta>{node.data.date}</Meta>
    <Title to={postUrl(node.uid)}>{node.data.title.text}</Title>
  </Container>
)
